<template>
  <div>
    <ManageType />
  </div>
</template>
<script>
import ManageType from "@/components/Type/ManageType";
export default {
  components: {
    ManageType,
  },
  created() {
  },
};
</script>
